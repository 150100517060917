.customModal {
  position: fixed;
  top: 0;
  left: 0;
  /*z-index: 1055;*/
  /*display: none;*/
  z-index: -99;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.customModal.show {
  z-index: 999990;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.customModal.fade .modal-dialog {
  /*transition: transform 0.3s ease-out;*/
  /*transform: translateY(-50px);*/
  transition: opacity 0.3s ease-out;
  opacity: 0;
}

.customModal .overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px);
}

.modal-title {
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body-scroll {
  overflow-y: auto;
  max-height: 70vh;
}

.modal-open {
  overflow: hidden !important;
  max-height: 100vh;
}

@media (prefers-reduced-motion: reduce) {
  .customModal.fade .modal-dialog {
    transition: none;
  }
}

.customModal.show .modal-dialog {
  /*transform: none;*/
  opacity: 1;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  /*background-color: #fff;*/
  background-clip: padding-box;
  /*border: 1px solid rgba(0, 0, 0, .2);*/
  /*border-radius: .3rem;*/
  margin-left: auto;
  margin-right: auto;
  outline: 0;

  border-radius: 6px;
  border: 1px solid rgb(151 151 151 / 20%);
  background: #213441;
  background: linear-gradient(180deg, #213441 100%, #15242f 100%, #979797 20%);
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.15);
  color: #fff;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px 0;
  /*border-bottom: 1px solid #dee2e6;*/
  /*border-top-left-radius: calc(0.3rem - 1px);*/
  /*border-top-right-radius: calc(0.3rem - 1px);*/
}

.modal-header .btn-close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 20px 25px;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 850px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 576px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 768px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-dialog {
    width: 100%;
    margin: 0;
    position: fixed;
    bottom: 32px;
  }

  .modal-content {
    max-width: 100% !important;
  }

  .modal-header,
  .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-title {
    font-size: 1.1em;
  }
}

@media (max-width: 992px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1200px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1400px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

/* custom style */
.modal-style1 .modal-content {
  border: solid 1px transparent;
  background-color: var(--wallet-selector-content-bg, var(--content-bg));
  background-image: linear-gradient(var(--content-bg), var(--content-bg)),
    linear-gradient(
      135deg,
      rgba(0, 255, 209, 0.2) 0%,
      rgba(147, 62, 255, 0.2) 100%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;

  max-width: 490px;
  color: #fff;
}

.modal-style1 .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.modal-style1 .modal-content:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #00ffd1 0%, #933eff 100%);
  filter: blur(30px);
  opacity: 0.5;
  transition: opacity 0.3s;
  border-radius: inherit;
}
